import * as React from "react";

import { IResponsiveImage } from "@lib/types/IResponsiveImage";
import { Image } from "react-datocms";

const ResponsiveImage = (props: IResponsiveImage) => {
  const { responsiveImage: data, ...rest } = props;

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image data={data} fadeInDuration={0} intersectionMargin="200px 200px 200px 200px" {...rest} />
  );
};

export default ResponsiveImage;
