import styles from "./Layout.module.scss";
import Head from "next/head";

import { renderMetaTags, SeoOrFaviconTag } from "react-datocms";

import * as React from "react";
import CookieConsent from "@components/cookie-consent/CookieConsent";
import Navigation from "@components/navigation/Navigation";
import Menu from "@components/menu/Menu";
import Footer from "@components/footer/Footer";
import Modal from "@components/modal/Modal";
import PreviewBar from "@components/previewBar/PreviewBar";
import { AnimatePresence, motion } from "framer-motion";
import { EntityNavigation } from "@lib/entities/EntityNavigation";
import { EntityFooter } from "@lib/entities/EntityFooter";
import { EntityGlobal } from "@lib/entities/EntityGlobal";
import { EntitySite } from "@lib/entities/EntitySite";

export type TLayout = {
  preview?: boolean;
  seo?: SeoOrFaviconTag[];
  navigation: EntityNavigation;
  footer: EntityFooter;
  site: EntitySite;
  global: EntityGlobal;
  children: React.ReactNode;
  isNavigationInverted?: boolean;
};

const Layout = (props: TLayout) => {
  const { preview, seo, footer, site, navigation, global, isNavigationInverted } = props;

  return (
    <>
      {site && (
        <Head>
          {site?.globalSeo?.fallbackSeo?.image?.url && (
            <meta property="og:image" content={site.globalSeo.fallbackSeo.image.url} />
          )}
          {site?.globalSeo?.siteName && (
            <meta property="og:title" content={site.globalSeo.siteName} />
          )}
          {site?.globalSeo?.fallbackSeo?.image?.width && (
            <meta
              property="og:image:width"
              content={site.globalSeo.fallbackSeo.image.width.toString()}
            />
          )}
          {site?.globalSeo?.fallbackSeo?.image?.height && (
            <meta
              property="og:image:height"
              content={site.globalSeo.fallbackSeo.image.height.toString()}
            />
          )}
        </Head>
      )}
      {seo && <Head>{renderMetaTags(seo)}</Head>}

      <div className={styles.layout}>
        {props.children}
        <Footer footer={footer} />
      </div>
    </>
  );
};

export default Layout;
