import styles from "./HeadingLarge.module.scss";

import * as React from "react";
import { cx } from "helpers/classNames";
import { renderMetaTags } from "react-datocms";

interface IHeadingLarge {
  children: React.ReactNode;
  className?: string;
  as?: string;
}

const HeadingLarge = (props: IHeadingLarge) => {
  const Tag = (props.as || "h1") as keyof JSX.IntrinsicElements;
  const className = cx(props.className, styles.text);
  return <Tag className={className}>{props.children}</Tag>;
};

export default HeadingLarge;
