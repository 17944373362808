import styles from "./Pill.module.scss";

import * as React from "react";
import { FILTER_CATEGORIES } from "@lib/enums/filterCategories";
import { EntityJobTag } from "@lib/entities/EntityJobTag";

export enum PILL_VARIANTS {
  Grey = "grey",
  OnGrey = "onGrey",
  Purple = "purple"
}

interface IPill {
  variant?: PILL_VARIANTS;
  children: React.ReactNode;
  tag?: EntityJobTag;
}

const getClassName = (type: PILL_VARIANTS | undefined) => {
  switch (type) {
    case PILL_VARIANTS.Purple: {
      return styles.pillPurple;
    }
    case PILL_VARIANTS.OnGrey: {
      return styles.pillOnGrey;
    }
    case PILL_VARIANTS.Grey: {
      return styles.pillGrey;
    }
    default: {
      return styles.pill;
    }
  }
};

const Pill = (props: IPill) => {
  const { children, tag } = props;
  let { variant } = props;

  if (tag) {
    switch (tag.tagGroup.variant) {
      case "PURPLE": {
        variant = PILL_VARIANTS.Purple;
        break;
      }
      default: {
        variant = PILL_VARIANTS.Grey;
        break;
      }
    }
  }

  return <span className={getClassName(variant)}>{children}</span>;
};

export default Pill;
