import styles from "./PageError.module.scss";

import * as React from "react";
import { EntityGlobal } from "@lib/entities/EntityGlobal";
import Container from "@components/containers/Container";
import RichText from "@components/rich-text/RichText";

export type TPageErrorProps = {
  global: EntityGlobal;
};

const PageError = (props: TPageErrorProps) => {
  return (
    <Container className={styles.container}>
      <div className={styles.heading}>{props.global.pageNotFoundHeading}</div>
      <div className={styles.paragraph}>
        <RichText richtext={props.global.pageNotFoundParagraph} />
      </div>
    </Container>
  );
};

export default PageError;
