import styles from "./ButtonFooterText.module.scss";

import * as React from "react";
import Button, { TButton } from "@components/buttons/Button";

const ButtonFooterText = (props: TButton) => {
  const { children, ...rest } = props;

  return (
    <Button className={styles.button} {...rest}>
      {children}
    </Button>
  );
};

export default ButtonFooterText;
