import styles from "./BodyLarge.module.scss";

import * as React from "react";
import { cx } from "helpers/classNames";

export enum BODY_LARGE_VARIANTS {
  Indent = "indent"
}

export type TBodyLarge = {
  variant?: BODY_LARGE_VARIANTS;
  children: React.ReactNode;
  className?: string;
  as?: string;
};

const getClassName = (variant: BODY_LARGE_VARIANTS | undefined) => {
  switch (variant) {
    case BODY_LARGE_VARIANTS.Indent: {
      return styles.textIndent;
    }
    default: {
      return styles.text;
    }
  }
};

const BodyLarge = (props: TBodyLarge) => {
  const Tag = (props.as || "p") as keyof JSX.IntrinsicElements;
  const className = cx(props.className, getClassName(props.variant));
  return <Tag className={className}>{props.children}</Tag>;
};

export default BodyLarge;
