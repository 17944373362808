import styles from "./Footer.module.scss";

import * as React from "react";

import ButtonFooter from "@components/button-footer/ButtonFooter";
import ButtonFooterText from "@components/button-footer-text/ButtonFooterText";
import Container from "@components/containers/Container";
import FooterLogo from "@components/footer-logo/FooterLogo";
import * as Analytics from "@lib/tracking/Analytics";

import { TFooter } from "./types";

const Footer = (props: TFooter) => {
  const { footer } = props;

  return (
    <Container
      className={styles.container}
      as={"section"}
      labelledby="Footer"
      trackingCategory="Footer"
    >
      <ul className={styles.menu}>
        {props.footer.menu.map((item) => {
          return (
            <li key={item.id} className={styles.menuItem}>
              <ButtonFooter
                {...item}
                onClick={() => {
                  Analytics.trackEvent("click", "footer", item.title);
                }}
              >
                {item.title}
              </ButtonFooter>
            </li>
          );
        })}
      </ul>

      <FooterLogo />

      <div className={styles.final}>
        <div>&copy; Doghouse {new Date().getFullYear()}</div>
        <h1 className={styles.payoff}>{footer.payoff}</h1>
        <div>
          <ul className={styles.list}>
            {footer.buttonPrivacyPolicy.map((item) => {
              return (
                <li key={item.id}>
                  <ButtonFooterText
                    {...item}
                    onClick={() => {
                      Analytics.trackEvent("click", "footer", item.title);
                    }}
                  >
                    {item.title}
                  </ButtonFooterText>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
