import styles from "./Testimonial.module.scss";

import * as React from "react";
import ResponsiveImage from "@components/responsive-image/ResponsiveImage";
import { IReference } from "@lib/types/IReference";
import Button from "@components/buttons/Button";
import { cx } from "helpers/classNames";

export enum TESTIMONIAL_VARIANTS {
  Default = "default",
  Short = "short",
  JobDetail = "jobdetail"
}

export type TReferenceProps = {
  reference: IReference;
  variant?: TESTIMONIAL_VARIANTS;
  isInverted?: boolean;
  onButtonToggle?: (isOpen: boolean) => void;
};

const getClassName = (type: TESTIMONIAL_VARIANTS | undefined, isInverted?: boolean) => {
  switch (type) {
    case TESTIMONIAL_VARIANTS.JobDetail: {
      return cx(styles.referenceJobDetail);
    }
    case TESTIMONIAL_VARIANTS.Short: {
      return cx(styles.reference, isInverted ? styles.isInverted : null);
    }
    default: {
      return cx(styles.reference, isInverted ? styles.isInverted : null);
    }
  }
};

const Testimonial = (props: TReferenceProps) => {
  const { onButtonToggle } = props;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const buttonCopy = isOpen ? "Close full review" : "View full review";

  const classNameButton = isOpen ? styles.buttonActive : styles.button;

  const { reference } = props;
  return (
    <div className={getClassName(props.variant, props.isInverted)}>
      <div className={styles.quote}>{`“${reference.quote}”`}</div>
      <div className={styles.contact}>
        <div className={styles.thumbnail}>
          {reference.thumbnail && (
            <ResponsiveImage
              responsiveImage={reference.thumbnail.responsiveImage}
              layout="fill"
              objectFit="cover"
            />
          )}
          {!reference.thumbnail && <div className={styles.face}>:)</div>}
        </div>
        <div className={styles.details}>
          <div>{reference.name}</div>
          <div>{reference.jobTitle}</div>
        </div>
      </div>
      {props.variant === TESTIMONIAL_VARIANTS.Default && (
        <div>
          <Button
            className={classNameButton}
            onClick={() => {
              setIsOpen(!isOpen);
              if (onButtonToggle) {
                onButtonToggle(!isOpen);
              }
            }}
          >
            <svg
              className={styles.icon}
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 13L16 19L22 13" strokeWidth="2" strokeLinejoin="round" />
            </svg>
            <span>{buttonCopy}</span>
          </Button>
        </div>
      )}
      {isOpen && <div className={styles.review}>{reference.fullReview}</div>}
    </div>
  );
};

export default Testimonial;
