import styles from "./FooterLogo.module.scss";

import * as React from "react";

import Logo from "@components/logos/Logo";

const FooterLogo = () => {
  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
    </div>
  );
};

export default FooterLogo;
